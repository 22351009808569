import React from 'react';
import { Check, Package, ShoppingCart, Share2, Search, Rocket, Star, Users } from 'lucide-react';

const AnimatedBackground = () => {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div className="relative w-full h-full">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className={`
              absolute animate-float
              w-32 h-32 md:w-40 md:h-40
              rounded-3xl opacity-10
              bg-gradient-to-r from-blue-500 to-purple-500
              backdrop-blur-3xl
            `}
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 10}s`,
              animationDuration: `${20 + Math.random() * 10}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

const ServicesPage = () => {
  const mainServices = [
    {
      icon: <Package className="w-8 h-8" />,
      title: "Custom Website Design for Authors",
      description: "A beautiful, functional website is the cornerstone of an author's success. We design fully customized websites that reflect your unique style and branding.",
      packages: [
        {
          name: "Basic Author Site",
          price: "$799",
          features: [
            "Up to 3 pages with basic functionality",
            "Custom homepage",
            "Book showcase page",
            "Contact page",
            "Responsive design",
            "Basic content management system"
          ]
        },
        {
          name: "Professional Author Site",
          price: "$1,499",
          features: [
            "Up to 5 pages",
            "Enhanced branding",
            "Advanced features",
            "Custom colors and fonts",
            "Extended functionality",
            "Premium support"
          ]
        },
        {
          name: "Premium Custom Site",
          price: "$2,999+",
          features: [
            "Unlimited pages",
            "Advanced features",
            "Full customization",
            "Premium integrations",
            "Priority support",
            "Advanced CMS"
          ]
        }
      ]
    }
  ];

  const additionalServices = [
    {
      icon: <ShoppingCart className="w-6 h-6" />,
      title: "eCommerce Setup",
      price: "From $599",
      description: "Sell books, merchandise, and special editions directly from your site",
      features: [
        "WooCommerce or Shopify integration",
        "Secure payment processing",
        "Product pages setup",
        "Inventory management",
        "Shipping configuration"
      ]
    },
    {
      icon: <Share2 className="w-6 h-6" />,
      title: "Blog & Social Integration",
      price: "From $399",
      description: "Keep your audience engaged with integrated communication tools",
      features: [
        "Blog setup with categories",
        "Newsletter integration",
        "Social media feeds",
        "Contact form setup",
        "Email notifications"
      ]
    },
    {
      icon: <Search className="w-6 h-6" />,
      title: "SEO Optimization",
      price: "From $499",
      description: "Help readers find your books with author-focused SEO",
      features: [
        "Keyword research",
        "Meta tags optimization",
        "Technical SEO setup",
        "Analytics integration",
        "Performance monitoring"
      ]
    },
    {
      icon: <Rocket className="w-6 h-6" />,
      title: "Book Launch Pages",
      price: "From $299",
      description: "High-converting landing pages for your book launches",
      features: [
        "Custom design",
        "Countdown timer",
        "Pre-order integration",
        "Lead generation",
        "Analytics tracking"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white via-blue-50 to-purple-50">
      {/* Hero Section */}
      {/* Hero Section - Update these padding values */}
<section className="relative pt-32 pb-16 px-4"> {/* Adjusted padding bottom */}
  <AnimatedBackground />
  <div className="container mx-auto text-center max-w-4xl relative z-10 py-4"> {/* Added py-4 */}
  <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 leading-relaxed p-2">
  Services & Pricing
</h1>
    <p className="text-xl text-gray-600 mb-8">
      Welcome to WriteToSite.com, where we specialize in building professional, high-performing websites tailored to authors.
    </p>
  </div>
</section>

      {/* Feature Highlights */}
      <section className="py-16 px-4 bg-gradient-to-b from-transparent to-blue-50/30">
        <div className="container mx-auto">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white/50 backdrop-blur-sm p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
              <div className="text-blue-600 mb-4">
                <Package className="w-8 h-8" />
              </div>
              <h3 className="text-xl font-bold mb-2">Custom Website Design</h3>
              <p className="text-gray-600">Tailored solutions that showcase your books and author brand effectively.</p>
            </div>

            <div className="bg-white/50 backdrop-blur-sm p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
              <div className="text-blue-600 mb-4">
                <Star className="w-8 h-8" />
              </div>
              <h3 className="text-xl font-bold mb-2">Author-Focused Features</h3>
              <p className="text-gray-600">Book previews, reader engagement tools, and newsletter integration.</p>
            </div>

            <div className="bg-white/50 backdrop-blur-sm p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
              <div className="text-blue-600 mb-4">
                <Users className="w-8 h-8" />
              </div>
              <h3 className="text-xl font-bold mb-2">Grow Your Readership</h3>
              <p className="text-gray-600">Built-in marketing tools to help you connect with more readers.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Main Services */}
      <section className="py-20 px-4">
        <div className="container mx-auto">
          {mainServices.map((service, index) => (
            <div key={index} className="mb-20">
              <div className="text-center mb-12">
                <div className="inline-block p-3 rounded-xl bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 mb-4">
                  {service.icon}
                </div>
                <h2 className="text-3xl font-bold mb-4">{service.title}</h2>
                <p className="text-gray-600 max-w-2xl mx-auto">{service.description}</p>
              </div>

              <div className="grid md:grid-cols-3 gap-8">
                {service.packages.map((pkg, idx) => (
                  <div key={idx} className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden flex flex-col h-full">
                    <div className="p-8 flex-grow">
                      <h3 className="text-2xl font-bold mb-4">{pkg.name}</h3>
                      <div className="text-3xl font-bold text-blue-600 mb-6">{pkg.price}</div>
                      <ul className="space-y-4">
                        {pkg.features.map((feature, fidx) => (
                          <li key={fidx} className="flex items-start gap-2">
                            <Check className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="p-4 border-t mt-auto">
                      <button className="w-full py-3 px-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg hover:from-blue-700 hover:to-purple-700 transition-colors">
                        Get Started
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Additional Services */}
      <section className="py-20 px-4 bg-white">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Additional Services</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {additionalServices.map((service, index) => (
              <div key={index} className="bg-gradient-to-b from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300">
                <div className="flex items-start gap-4">
                  <div className="p-3 rounded-xl bg-white text-blue-600">
                    {service.icon}
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">{service.title}</h3>
                    <div className="text-blue-600 font-bold mb-2">{service.price}</div>
                    <p className="text-gray-600 mb-4">{service.description}</p>
                    <ul className="space-y-2">
                      {service.features.map((feature, idx) => (
                        <li key={idx} className="flex items-center gap-2">
                          <Check className="w-4 h-4 text-green-500" />
                          <span className="text-gray-600">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 px-4">
        <div className="container mx-auto text-center max-w-3xl">
          <h2 className="text-3xl font-bold mb-6">Ready to Build Your Author Website?</h2>
          <p className="text-xl text-gray-600 mb-8">
            Take the first step toward building your online presence and selling more books.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })} 
              className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              Schedule a Consultation
            </button>
            <button className="px-8 py-4 border-2 border-blue-600 text-blue-600 rounded-full hover:bg-blue-50 transition-all duration-300">
              View Portfolio
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesPage;