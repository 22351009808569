import React, { useState } from 'react';

const FAQsPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What makes WriteToSite.com different from other web design agencies?",
      answer:
        "We specialize in building websites exclusively for authors. Our team understands the unique needs of writers, such as showcasing books, integrating eCommerce for special editions, and building an online presence that reflects your personal brand.",
    },
    {
      question: "How long does it take to build an author website?",
      answer:
        "The timeline depends on the complexity of your website. A basic author website typically takes 2-3 weeks, while a more advanced site with eCommerce and custom features may take 4-6 weeks. We’ll provide a clear timeline during the consultation process.",
    },
    {
      question: "Can I sell my books directly from my website?",
      answer:
        "Absolutely! We can set up an online store where you can sell books, special editions, merchandise, and even digital downloads like eBooks or audiobooks. We’ll handle payment integration, inventory management, and shipping configurations.",
    },
    {
      question: "Do I need technical knowledge to update my website?",
      answer:
        "No! We build websites with user-friendly platforms like WordPress, so you can easily update content, add blog posts, or manage your store without any coding experience. We also provide tutorials to help you navigate your site.",
    },
    {
      question: "Will my website be optimized for search engines (SEO)?",
      answer:
        "Yes, all our websites include basic SEO optimization to help readers find you online. For more advanced SEO strategies, we offer an add-on package that includes in-depth keyword research, meta tags, and ongoing optimization support.",
    },
    {
      question: "Can I integrate social media and newsletters with my website?",
      answer:
        "Yes, we can integrate your social media feeds and newsletter sign-up forms (Mailchimp, ConvertKit, etc.) directly into your website. This helps you connect with readers and grow your audience seamlessly.",
    },
    {
      question: "What happens after my website is launched?",
      answer:
        "Once your website is live, we offer ongoing support to address any issues or make updates as needed. If you prefer to manage the site yourself, we provide a detailed guide and optional training sessions.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="py-20 px-4 bg-gradient-to-br from-white via-blue-50 to-purple-50 relative">
      <div className="container mx-auto">
        {/* Heading */}
        <div className="mb-12 text-center">
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
            Frequently Asked Questions
          </h1>
          <p className="text-xl text-gray-600 mt-4">
            Have questions? We've got answers! Explore our FAQs to learn more about how WriteToSite.com can help you build the perfect author website.
          </p>
        </div>

        {/* FAQs */}
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`border rounded-2xl p-6 shadow-lg transition-all duration-300 ${
                activeIndex === index ? "bg-white" : "bg-white/70"
              }`}
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <h2 className="text-xl font-bold text-gray-800">{faq.question}</h2>
                <button
                  className={`transform transition-transform duration-300 ${
                    activeIndex === index ? "rotate-180" : ""
                  }`}
                >
                  ⮟
                </button>
              </div>
              {activeIndex === index && (
                <p className="mt-4 text-gray-600 leading-relaxed">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQsPage;
