import React from 'react';
import { Book, Users, BookOpen, Smartphone, Mail, ShoppingCart, Star, Search } from 'lucide-react';

const ServicesFeatures = () => {
  const features = [
    {
      icon: <Book className="w-8 h-8" />,
      title: "Dedicated Book Pages",
      description: "Custom-designed pages for each book with direct purchase links to major platforms like Amazon, Barnes & Noble, and more."
    },
    {
      icon: <Users className="w-8 h-8" />,
      title: "Members-Only Areas",
      description: "Exclusive content areas for your dedicated readers, including bonus chapters, behind-the-scenes content, and early access."
    },
    {
      icon: <BookOpen className="w-8 h-8" />,
      title: "Chapter Previews",
      description: "Beautiful HTML preview sections that let readers sample your work while protecting your content."
    },
    {
      icon: <Smartphone className="w-8 h-8" />,
      title: "Mobile-Optimized Design",
      description: "Fully responsive websites that look and work perfectly on all devices, from phones to desktop computers."
    },
    {
      icon: <Search className="w-8 h-8" />,
      title: "SEO-Friendly Structure",
      description: "Built-in search engine optimization to help new readers discover your books and content."
    },
    {
      icon: <Mail className="w-8 h-8" />,
      title: "Newsletter Integration",
      description: "Grow your audience with integrated newsletter signups and automated reader engagement tools."
    }
  ];

  return (
    <section className="py-12 px-4 bg-gradient-to-b from-white via-blue-50 to-purple-50"> {/* Changed from py-20 to py-12 */}
      <div className="container mx-auto">
        {/* Header */}
        <div className="text-center max-w-3xl mx-auto mb-12"> {/* Changed from mb-16 to mb-12 */}
          <div className="inline-block animate-bounce-slow">
            <span className="px-6 py-2 rounded-full bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 font-semibold">
              Essential Features
            </span>
          </div>
          <h2 className="text-4xl font-bold mt-6 mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
              Everything You Need in One Place
            </span>
          </h2>
          <p className="text-gray-600 text-lg">
            Comprehensive tools and features designed specifically for authors to showcase their work and engage with readers. We use modern technologies like React and Node.js to build high-performance, scalable, and custom websites that are both visually stunning and highly functional. This ensures a seamless user experience, fast load times, and the flexibility to grow with your career.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8"> {/* Changed from mb-12 to mb-8 */}
          {features.map((feature, index) => (
            <div 
              key={index}
              className="group p-8 rounded-2xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 relative overflow-hidden"
            >
              {/* Feature Icon */}
              <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-blue-600/5 to-purple-600/5 rounded-bl-[100px] transition-all duration-300 group-hover:scale-110"></div>
              <div className="relative">
                <div className="inline-block p-3 rounded-xl bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 mb-4 group-hover:scale-110 transition-transform duration-300">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-800">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <a 
            href="/services" 
            className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium group"
          >
            Explore Our Features
            <span className="ml-2 transform group-hover:translate-x-1 transition-transform">→</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ServicesFeatures;