import React, { useState } from 'react';
import { Check, ChevronRight, Calendar, Book, User, Package } from 'lucide-react';

const ContactForm = () => {
  const initialFormState = {
    package: '',
    name: '',
    email: '',
    phone: '',
    bookTitle: '',
    genre: '',
    description: '',
    meetingDate: '',
    meetingTime: ''
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(initialFormState);

  const TELEGRAM_BOT_TOKEN = '7792080768:AAGdZ6jv4H4ueprl4aPJpgXRSmuGaxfxcxo';
  const TELEGRAM_CHAT_ID = '1523610682';

  const steps = [
    { number: 1, title: "Choose Package", icon: <Package className="w-5 h-5" /> },
    { number: 2, title: "Your Details", icon: <User className="w-5 h-5" /> },
    { number: 3, title: "Book Info", icon: <Book className="w-5 h-5" /> },
    { number: 4, title: "Schedule Meeting", icon: <Calendar className="w-5 h-5" /> }
  ];

  const packages = [
    { id: 'silver', name: 'Basic', price: '$799' },
    { id: 'gold', name: 'Professional', price: '$1499' },
    { id: 'platinum', name: 'Premium', price: '$2999' }
  ];

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData(initialFormState);
    setCurrentStep(1);
  };

  const sendToTelegram = async (formData) => {
    const message = `This message is from WritetoSite.com Contact Form\n\n` +
      `📦 Package: ${formData.package}\n` +
      `👤 Name: ${formData.name}\n` +
      `📧 Email: ${formData.email}\n` +
      `📱 Phone: ${formData.phone}\n` +
      `📚 Book Title: ${formData.bookTitle}\n` +
      `📖 Genre: ${formData.genre}\n` +
      `📝 Description: ${formData.description}\n` +
      `📅 Meeting Date: ${formData.meetingDate}\n` +
      `⏰ Meeting Time: ${formData.meetingTime}`;

    try {
      const response = await fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: message,
          parse_mode: 'HTML'
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send message to Telegram');
      }
    } catch (error) {
      console.error('Error sending to Telegram:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendToTelegram(formData);
      alert('Form submitted successfully! We will contact you soon.');
      resetForm(); // Reset form after successful submission
    } catch (error) {
      alert('There was an error submitting the form. Please try again later.');
    }
  };

  const nextStep = () => setCurrentStep(curr => Math.min(curr + 1, 4));
  const prevStep = () => setCurrentStep(curr => Math.max(curr - 1, 1));

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-white via-blue-50 to-purple-50">
      <div className="container mx-auto max-w-4xl">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6">Get Started</h2>
          <div className="w-24 h-1 bg-gradient-to-r from-blue-600 to-purple-600 mx-auto rounded-full mb-8"></div>
          
          {/* Progress Steps */}
          <div className="flex justify-between items-center max-w-2xl mx-auto mb-12">
            {steps.map((step, index) => (
              <div key={index} className="flex items-center">
                <div className={`flex flex-col items-center ${index !== steps.length - 1 ? 'w-full' : ''}`}>
                  <div className={`flex items-center justify-center w-10 h-10 rounded-full border-2 
                    ${currentStep > step.number 
                      ? 'bg-blue-600 border-blue-600 text-white' 
                      : currentStep === step.number 
                        ? 'border-blue-600 text-blue-600' 
                        : 'border-gray-300 text-gray-300'}`}>
                    {currentStep > step.number ? <Check className="w-5 h-5" /> : step.icon}
                  </div>
                  <div className="text-sm mt-2 font-medium text-gray-600">{step.title}</div>
                </div>
                {index !== steps.length - 1 && (
                  <div className={`h-[2px] w-full mx-4 ${
                    currentStep > step.number ? 'bg-blue-600' : 'bg-gray-300'
                  }`} />
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-8">
          <form onSubmit={handleSubmit}>
            {/* Step 1: Package Selection */}
            {currentStep === 1 && (
              <div className="space-y-6">
                <h3 className="text-2xl font-bold mb-6">Select Your Package</h3>
                <div className="grid gap-4">
                  {packages.map((pkg) => (
                    <label key={pkg.id} className="relative">
                      <input
                        type="radio"
                        name="package"
                        value={pkg.id}
                        className="hidden"
                        onChange={handleInputChange}
                        checked={formData.package === pkg.id}
                      />
                      <div className={`p-4 border-2 rounded-xl cursor-pointer transition-all
                        ${formData.package === pkg.id 
                          ? 'border-blue-600 bg-blue-50' 
                          : 'border-gray-200 hover:border-blue-400'}`}>
                        <div className="flex justify-between items-center">
                          <div>
                            <div className="font-bold text-lg">{pkg.name}</div>
                            <div className="text-gray-600">{pkg.price}</div>
                          </div>
                          {formData.package === pkg.id && (
                            <Check className="text-blue-600 w-6 h-6" />
                          )}
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            )}

            {/* Step 2: Contact Information */}
            {currentStep === 2 && (
              <div className="space-y-6">
                <h3 className="text-2xl font-bold mb-6">Your Contact Information</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-gray-700 mb-2">Full Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Phone Number</label>
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Step 3: Book Information */}
            {currentStep === 3 && (
              <div className="space-y-6">
                <h3 className="text-2xl font-bold mb-6">Book Information</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-gray-700 mb-2">Book Title</label>
                    <input
                      type="text"
                      name="bookTitle"
                      value={formData.bookTitle}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Genre</label>
                    <select
                      name="genre"
                      value={formData.genre}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    >
                      <option value="">Select a genre</option>
                      <option value="fiction">Fiction</option>
                      <option value="non-fiction">Non-Fiction</option>
                      <option value="mystery">Mystery</option>
                      <option value="romance">Romance</option>
                      <option value="sci-fi">Science Fiction</option>
                      <option value="fantasy">Fantasy</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Brief Description</label>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      rows="4"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
            )}

            {/* Step 4: Schedule Meeting */}
            {currentStep === 4 && (
              <div className="space-y-6">
                <h3 className="text-2xl font-bold mb-6">Schedule Your Consultation</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-gray-700 mb-2">Preferred Date</label>
                    <input
                      type="date"
                      name="meetingDate"
                      value={formData.meetingDate}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Preferred Time</label>
                    <select
                      name="meetingTime"
                      value={formData.meetingTime}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    >
                      <option value="">Select a time</option>
                      <option value="9:00">9:00 AM</option>
                      <option value="10:00">10:00 AM</option>
                      <option value="11:00">11:00 AM</option>
                      <option value="13:00">1:00 PM</option>
                      <option value="14:00">2:00 PM</option>
                      <option value="15:00">3:00 PM</option>
                      <option value="16:00">4:00 PM</option>
                    </select>
                  </div>
                </div>
              </div>
            )}

            {/* Navigation Buttons */}
            <div className="flex justify-between mt-8">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={prevStep}
                  className="px-6 py-2 text-blue-600 font-medium hover:text-blue-800 transition-colors"
                >
                  Back
                </button>
              )}
              {currentStep < 4 ? (
                <button
                  type="button"
                  onClick={nextStep}
                  className="ml-auto px-8 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-colors"
                >
                  Continue
                </button>
              ) : (
                <button
                  type="submit"
                  className="ml-auto px-8 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-colors"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;