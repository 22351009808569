import React, { useState, useEffect } from "react";
import book1 from '../images/scoc.png';
import book2 from '../images/book2.png';
import book3 from '../images/book3.png';

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const bookCovers = [book1, book2, book3];

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleImageError = (e) => {
    console.log("Image failed to load");
    e.target.src = "/api/placeholder/320/480";
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative flex items-center justify-center py-16 pt-32 overflow-hidden">
      {/* Animated Geometric Shapes */}
      <div className="absolute inset-0 -z-10">
        {[...Array(10)].map((_, i) => (
          <div
            key={i}
            className="absolute animate-float bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg opacity-20"
            style={{
              width: `${50 + Math.random() * 50}px`,
              height: `${50 + Math.random() * 50}px`,
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${10 + Math.random() * 10}s`,
            }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="container mx-auto px-4 text-center relative z-10">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Content */}
          <div className="flex-1 text-center md:text-left">
            <div className="inline-block animate-bounce-slow">
              <span className="px-6 py-2 rounded-full bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 font-semibold">
                Professional Author Websites
              </span>
            </div>

            <h1 className="text-5xl md:text-7xl font-bold mb-6 relative overflow-hidden">
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 relative">
                Websites Crafted
                <div className="absolute inset-0 animate-sheen bg-gradient-to-r from-transparent via-white/20 to-transparent skew-x-[-45deg]" />
              </span>
              <span className="block mt-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600 relative">
                for Authors
                <div className="absolute inset-0 animate-sheen bg-gradient-to-r from-transparent via-white/20 to-transparent skew-x-[-45deg]" />
              </span>
            </h1>

            <p className="text-xl md:text-2xl text-gray-600 mb-8 max-w-2xl mx-auto md:mx-0 leading-relaxed">
              Transform your literary presence with a custom website designed to{" "}
              <span className="text-blue-600 font-semibold">showcase your work</span> and{" "}
              <span className="text-purple-600 font-semibold">connect with readers</span>.
            </p>

            <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start items-center">
              <button
                onClick={() => scrollToSection('pricing')}
                className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium transform hover:scale-105 group"
              >
                Choose Your Package
                <span className="inline-block ml-2 transition-transform group-hover:translate-x-1">→</span>
              </button>
              <button
                onClick={() => scrollToSection('services')}
                className="px-8 py-4 bg-white text-blue-600 rounded-full hover:bg-blue-50 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium border-2 border-blue-600 transform hover:scale-105"
              >
                Learn More
              </button>
            </div>
          </div>

          {/* Floating Book Covers */}
          <div className="md:block flex-shrink-0 ml-8 relative w-96 h-96">
            <div className="relative group">
              {bookCovers.map((cover, index) => (
                <div
                  key={index}
                  className={`absolute left-0 top-0 transition-all duration-500 ease-out transform ${
                    isVisible
                      ? `opacity-100 ${
                          index === 0
                            ? 'rotate-[-15deg] translate-x-0'
                            : index === 1
                            ? 'rotate-0 translate-x-8'
                            : 'rotate-[15deg] translate-x-16'
                        }`
                      : 'opacity-0 translate-x-24'
                  } ${
                    hoveredIndex === index
                      ? 'scale-110 z-20 translate-y-[-10px]'
                      : ''
                  }`}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <div className="absolute inset-0 bg-black opacity-20 rounded-lg transform translate-y-4 blur-lg"></div>
                  <img
                    src={cover}
                    alt={`Book Cover ${index + 1}`}
                    onError={handleImageError}
                    className="relative w-64 h-96 rounded-lg shadow-xl"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;