import React from 'react';
import { Mail, Phone, Send } from 'lucide-react';

const AnimatedBackground = () => {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div className="relative w-full h-full">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className={`
              absolute animate-float
              w-32 h-32 md:w-40 md:h-40
              rounded-3xl opacity-10
              bg-gradient-to-r from-blue-500 to-purple-500
              backdrop-blur-3xl
            `}
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 10}s`,
              animationDuration: `${20 + Math.random() * 10}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

const ContactPage = () => {
  return (
    <>
      {/* Wrapping with React Fragment */}
      <div className="min-h-screen bg-gradient-to-b from-white via-blue-50 to-purple-50 relative">
        <AnimatedBackground />

        <section className="relative py-24 px-4">
          <div className="container mx-auto text-center max-w-3xl relative z-10">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 leading-relaxed">
              Contact Us
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              We’d love to hear from you! Fill out the form below or reach out to us directly.
            </p>

            {/* Contact Info */}
            <div className="flex flex-col sm:flex-row justify-center gap-8 mb-12">
              <div className="flex items-center gap-4">
                <Mail className="w-8 h-8 text-blue-600" />
                <span className="text-lg text-gray-700">
                  <a href="mailto:sales@writetosite.com" className="hover:underline">
                    sales@writetosite.com
                  </a>
                </span>
              </div>
              <div className="flex items-center gap-4">
                <Phone className="w-8 h-8 text-blue-600" />
                <span className="text-lg text-gray-700">(888) 522-4733</span>
              </div>
            </div>

            {/* Contact Form */}
            <form className="bg-white/50 backdrop-blur-md p-8 rounded-2xl shadow-lg max-w-2xl mx-auto">
              <div className="mb-6">
                <label htmlFor="name" className="block text-left text-gray-700 font-semibold mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your Name"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="email" className="block text-left text-gray-700 font-semibold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your Email"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="message" className="block text-left text-gray-700 font-semibold mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="5"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-300"
              >
                <Send className="inline-block mr-2" /> Send Message
              </button>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactPage;
