import React, { useEffect, useState } from "react";
import { Book, Users, Newspaper } from "lucide-react";
import { createClient } from "contentful";

const WritersCorner = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  // Initialize Contentful Client
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID, // Add your Contentful space ID
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN, // Add your Contentful access token
  });

  useEffect(() => {
    // Fetch latest blog posts
    const fetchBlogPosts = async () => {
      try {
        const response = await client.getEntries({
          content_type: "blogPost", // Replace with your Contentful content type ID
          order: "-sys.createdAt", // Fetch the latest posts
          limit: 3, // Limit to 3 posts
        });

        const articles = response.items.map((item) => ({
          title: item.fields.title,
          excerpt: item.fields.excerpt,
          slug: item.fields.slug,
          category: item.fields.category,
          readTime: item.fields.readTime || "Read More", // Optional
        }));

        setBlogPosts(articles);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchBlogPosts();
  }, [client]);

  return (
    <section className="py-20 px-4 bg-white">
      <div className="container mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-block">
            <span className="px-4 py-2 rounded-full bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 font-semibold">
              Writer's Corner
            </span>
          </div>
          <h2 className="text-4xl font-bold mt-6 mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
              Tips & Resources for Authors
            </span>
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Get insights on selling books, building a brand, and more
          </p>
        </div>

        {/* Blog Posts Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-12">
          {blogPosts.length > 0 ? (
            blogPosts.map((post, index) => (
              <article
                key={index}
                className="bg-gradient-to-b from-blue-50 to-purple-50 rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                {/* Category Badge */}
                <div className="flex justify-between items-center mb-4">
                  <span className="text-sm font-semibold text-blue-600">
                    {post.category}
                  </span>
                  <span className="text-sm text-gray-600">{post.readTime}</span>
                </div>

                {/* Icon */}
                <div className="inline-block p-3 rounded-xl bg-white text-blue-600 mb-4">
                  {index === 0 ? (
                    <Book className="w-6 h-6" />
                  ) : index === 1 ? (
                    <Users className="w-6 h-6" />
                  ) : (
                    <Newspaper className="w-6 h-6" />
                  )}
                </div>

                {/* Content */}
                <h3 className="text-xl font-bold mb-3 text-gray-800 hover:text-blue-600 transition-colors">
                  <a href={`/blog/${post.slug}`}>{post.title}</a>
                </h3>
                <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>

                {/* Read More Link */}
                <a
                  href={`/blog/${post.slug}`}
                  className="inline-flex items-center text-blue-600 font-semibold hover:text-blue-700 group"
                >
                  Read More
                  <span className="ml-1 transform transition-transform group-hover:translate-x-1">
                    →
                  </span>
                </a>
              </article>
            ))
          ) : (
            <p className="text-gray-600">Loading latest blog posts...</p>
          )}
        </div>

        {/* CTA Button */}
        <div className="text-center">
          <a
            href="/blog"
            className="inline-block px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium"
          >
            Read Our Blog
          </a>
        </div>
      </div>
    </section>
  );
};

export default WritersCorner;
