import React from 'react';
import { MessagesSquare, Palette, Rocket } from 'lucide-react';

const WorkingProcess = () => {
  const steps = [
    {
      icon: <MessagesSquare className="w-8 h-8" />,
      number: "01",
      title: "Consultation",
      description: "We discuss your goals and vision, understanding your unique needs as an author and what you want to achieve with your website."
    },
    {
      icon: <Palette className="w-8 h-8" />,
      number: "02",
      title: "Design & Build",
      description: "We create a professional website tailored to your brand, incorporating all the features needed to showcase your books and engage with readers."
    },
    {
      icon: <Rocket className="w-8 h-8" />,
      number: "03",
      title: "Launch & Support",
      description: "We launch your site and provide ongoing assistance to ensure everything runs smoothly as you connect with your audience."
    }
  ];

  return (
    <section className="py-20 px-4 bg-white">
      <div className="container mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
              Our Simple Process
            </span>
          </h2>
          <div className="w-24 h-1 bg-gradient-to-r from-blue-600 to-purple-600 mx-auto rounded-full"></div>
        </div>

        {/* Process Steps */}
        <div className="grid md:grid-cols-3 gap-8 mb-12">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className="relative group"
            >
              {/* Connector Line */}
              {index < steps.length - 1 && (
                <div className="hidden md:block absolute top-1/4 left-1/2 w-full h-[2px] bg-gradient-to-r from-blue-200 to-purple-200"></div>
              )}
              
              {/* Step Card */}
              <div className="relative bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 z-10">
                {/* Number Badge */}
                <div className="absolute -top-4 right-8 bg-gradient-to-r from-blue-600 to-purple-600 text-white text-sm font-bold px-3 py-1 rounded-full">
                  {step.number}
                </div>

                {/* Icon */}
                <div className="inline-block p-4 rounded-xl bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 mb-6">
                  {step.icon}
                </div>

                {/* Content */}
                <h3 className="text-xl font-bold mb-4 text-gray-800">{step.title}</h3>
                <p className="text-gray-600 leading-relaxed">{step.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Button */}
        <div className="text-center">
          <a 
            href="/contact" 
            className="inline-block px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium"
          >
            Get Started Today
          </a>
        </div>
      </div>
    </section>
  );
};

export default WorkingProcess;