import React, { useState } from 'react';
import { MapPin, Clock, Phone, Mail } from 'lucide-react';

const Footer = () => {
  const [email, setEmail] = useState('');

  const TELEGRAM_BOT_TOKEN = '7792080768:AAGdZ6jv4H4ueprl4aPJpgXRSmuGaxfxcxo';
  const TELEGRAM_CHAT_ID = '1523610682';

  const handleSubscribe = async (e) => {
    e.preventDefault();
    
    try {
      const message = `This message is from WritetoSite.com Email Subscription Form\n\n📧 New Subscriber Email: ${email}`;
      
      const response = await fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: message,
          parse_mode: 'HTML'
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send message to Telegram');
      }

      alert('Thank you for subscribing!');
      setEmail('');
    } catch (error) {
      console.error('Error sending to Telegram:', error);
      alert('There was an error subscribing. Please try again later.');
    }
  };

  return (
    <footer className="relative bg-gradient-to-br from-blue-900 to-purple-900 text-white overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="absolute animate-float opacity-10"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${15 + Math.random() * 10}s`,
            }}
          >
            <div className={`
              w-64 h-64 rounded-full
              bg-gradient-to-br from-blue-400 to-purple-400
              blur-3xl transform rotate-${Math.random() * 360}
            `} />
          </div>
        ))}
      </div>

      <div className="container mx-auto px-4 py-16 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* Company Info */}
          <div className="space-y-4">
            <a href="/" className="text-2xl font-bold text-white">WriteToSite</a>
            <p className="text-blue-100/80">
              Crafting beautiful digital spaces for authors to showcase their work and connect with readers worldwide.
            </p>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h3 className="text-lg font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/services" className="text-blue-100/80 hover:text-white transition-colors">Features</a>
              </li>
              <li>
                <a href="/demo-preview" className="text-blue-100/80 hover:text-white transition-colors">Demo Preview</a>
              </li>
              <li>
                <a href="/services" className="text-blue-100/80 hover:text-white transition-colors">Pricing</a>
              </li>
              <li>
                <a href="/blog" className="text-blue-100/80 hover:text-white transition-colors">Writer's Corner</a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="space-y-4">
            <h3 className="text-lg font-bold mb-4">Contact Info</h3>
            <ul className="space-y-3">
              <li className="flex items-center space-x-3">
                <MapPin size={18} className="text-blue-300" />
                <span className="text-blue-100/80">30 N Gould Street, Ste R, Sheridan, WY 82801</span>
              </li>
              <li className="flex items-center space-x-3">
                <Phone size={18} className="text-blue-300" />
                <span className="text-blue-100/80">(888) 522-4733</span>
              </li>
              <li className="flex items-center space-x-3">
                <Mail size={18} className="text-blue-300" />
                <span className="text-blue-100/80">sales@writetosite.com</span>
              </li>
              <li className="flex items-center space-x-3">
                <Clock size={18} className="text-blue-300" />
                <span className="text-blue-100/80">Mon-Fri: 9:00 AM - 6:00 PM</span>
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          <div className="space-y-4">
            <h3 className="text-lg font-bold mb-4">Newsletter</h3>
            <p className="text-blue-100/80">
              Subscribe to our newsletter for the latest website design tips for authors.
            </p>
            <form onSubmit={handleSubscribe} className="flex">
              <input 
                type="email" 
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 bg-white/10 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-white placeholder:text-blue-100/50 flex-1"
                required
              />
              <button 
                type="submit" 
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-r-lg transition-colors"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-white/10 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="text-blue-100/80">
            © 2024 WriteToSite. All Rights Reserved. Built and powered by mysitebroker.com.
          </div>
          <div className="flex space-x-6">
            <a href="/privacy-policy" className="text-blue-100/80 hover:text-white transition-colors">Privacy Policy</a>
            <a href="/terms-of-use" className="text-blue-100/80 hover:text-white transition-colors">Terms of Service</a>
            <a href="/faqs" className="text-blue-100/80 hover:text-white transition-colors">FAQs</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;