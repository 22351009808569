import React from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import ServicesFeatures from './ServicesFeatures';
import BookPreviewSection from './BookPreviewSection';
import WorkingProcess from './WorkingProcess';
import TestimonialsSection from './TestimonialsSection';
import PricingSection from './PricingSection';
import WritersCorner from './WritersCorner';
import ContactForm from './ContactForm';

const LandingPage = () => {
  return (
    <>
      <Header />
      <section id="hero">
        <HeroSection bookCoverSrc={process.env.PUBLIC_URL + "/assets/images/scoc.png"} />
      </section>
      <section id="services">
        <ServicesFeatures />
      </section>
      <section id="preview">
        <BookPreviewSection />
      </section>
      <section id="process">
        <WorkingProcess />
      </section>
      <section id="testimonials">
        <TestimonialsSection />
      </section>
      <section id="pricing">
        <PricingSection />
      </section>
      <section id="articles">
        <WritersCorner />
      </section>
      <section id="contact">
        <ContactForm />
      </section>
    </>
  );
};

export default LandingPage;