import React, { useState } from 'react';
import { Star, ChevronLeft, ChevronRight } from 'lucide-react';

const TestimonialsSection = () => {
  const testimonials = [
    {
      author: "Lucius Qayin",
      title: "Dark Fantasy Author",
      image: "/assets/images/authors/lucius.jpg",
      quote: "WriteToSite transformed my online presence completely. My book sales increased by 40% within the first month of launching my new website. Their understanding of author needs is unmatched.",
      stars: 5,
      bookCover: "/assets/images/books/lucius-book.png"
    },
    {
      author: "Carter Case",
      title: "Children's Series Writer",
      image: "/assets/images/authors/carter.png",
      quote: "The team at WriteToSite created exactly what I needed - a professional platform that makes it easy to connect with my readers. The chapter preview feature is brilliant! WritetoSite was able to create the perfect marketing machine for my Ned and Nancy series and companion Podcast.",
      stars: 5,
      bookCover: "/assets/images/books/carter-book.png"
    },
    {
      author: "Abigail Landon",
      title: "Self-Help Author",
      image: "/assets/images/authors/abigail.jpg",
      quote: "I couldn't be happier with my author website. It's beautiful, functional, and has helped me build a real community around my books. The reader engagement features are outstanding. The websites that WritetoSite builds are capable of showcasing books and extras in the perfect way.",
      stars: 5,
      bookCover: "/assets/images/books/abigail-book.png"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-white via-blue-50 to-purple-50">
      <div className="container mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-block">
            <span className="px-4 py-2 rounded-full bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 font-semibold">
              Testimonials
            </span>
          </div>
          <h2 className="text-4xl font-bold mt-6 mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
              What Authors Are Saying
            </span>
          </h2>
          <p className="text-xl text-gray-600">
            Join the many authors who trust WriteToSite
          </p>
        </div>

        {/* Testimonials Carousel */}
        <div className="relative max-w-5xl mx-auto">
          <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12">
            <div className="grid md:grid-cols-5 gap-8">
              {/* Book Cover */}
              <div className="md:col-span-2 flex justify-center">
                <div className="relative">
                  <img
                    src={testimonials[currentIndex].bookCover}
                    alt="Book Cover"
                    className="rounded-lg shadow-lg transform -rotate-3 transition-transform duration-300 hover:rotate-0"
                  />
                  <div className="absolute inset-0 bg-gradient-to-tr from-blue-600/10 to-purple-600/10 rounded-lg"></div>
                </div>
              </div>

              {/* Testimonial Content */}
              <div className="md:col-span-3 space-y-6">
                {/* Stars */}
                <div className="flex gap-1">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-6 h-6 text-yellow-400 fill-current" />
                  ))}
                </div>

                {/* Quote */}
                <blockquote className="text-xl text-gray-600 italic">
                  "{testimonials[currentIndex].quote}"
                </blockquote>

                {/* Author Info */}
                <div className="flex items-center gap-4">
                  <img
                    src={testimonials[currentIndex].image}
                    alt={testimonials[currentIndex].author}
                    className="w-16 h-16 rounded-full object-cover"
                  />
                  <div>
                    <div className="font-bold text-lg text-gray-800">
                      {testimonials[currentIndex].author}
                    </div>
                    <div className="text-gray-600">
                      {testimonials[currentIndex].title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-center gap-4 mt-8">
            <button
              onClick={prevTestimonial}
              className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl transition-all duration-300 text-blue-600 hover:text-blue-700"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              onClick={nextTestimonial}
              className="p-2 rounded-full bg-white shadow-lg hover:shadow-xl transition-all duration-300 text-blue-600 hover:text-blue-700"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>

          {/* Dots Indicator */}
          <div className="flex justify-center gap-2 mt-4">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-2 h-2 rounded-full transition-all duration-300 ${
                  index === currentIndex
                    ? 'bg-blue-600 w-6'
                    : 'bg-blue-200 hover:bg-blue-300'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;