import React from 'react';
import { Check, X } from 'lucide-react';

const PricingSection = () => {
  const plans = [
    {
      name: "Basic",
      price: "799",
      description: "Perfect for authors with one book looking to establish their online presence.",
      features: [
        "Professional single-page website",
        "Author bio and book description",
        "Purchase links integration",
        "First chapter HTML preview",
        "Responsive design",
        "Social media integration",
        "Basic SEO setup"
      ],
      addOns: [
        "Additional books: +$150 each",
        "Hosting & maintenance: +$30/month"
      ],
      popular: false
    },
    {
      name: "Advanced",
      price: "1,499",
      description: "Ideal for established authors with multiple books and growing audience.",
      features: [
        "Multi-page website with blog",
        "Up to 3 book pages included",
        "3 chapter HTML previews",
        "Members-only section",
        "Newsletter integration",
        "Enhanced SEO optimization",
        "Google Analytics setup",
        "3 hours website training"
      ],
      addOns: [
        "Additional books: +$100 each",
        "Advanced membership: +$500",
        "Hosting & maintenance: +$50/month"
      ],
      popular: true
    },
    {
      name: "Premium",
      price: "2,999",
      description: "Complete solution for professional authors wanting maximum impact.",
      features: [
        "Fully custom design",
        "Up to 6 book pages",
        "Unlimited chapter previews",
        "Advanced members area",
        "E-commerce integration",
        "Advanced SEO strategy",
        "Social media feed integration",
        "1 year maintenance included",
        "Priority support"
      ],
      addOns: [
        "Additional books: +$50 each",
        "Marketing materials: +$300",
        "Extended maintenance: +$75/month"
      ],
      popular: false
    }
  ];

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-white via-blue-50 to-purple-50">
      <div className="container mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
              Affordable Packages for Every Author
            </span>
          </h2>
          <p className="text-xl text-gray-600">Choose the plan that fits your needs</p>
        </div>

        {/* Pricing Cards */}
        <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {plans.map((plan, index) => (
            <div 
              key={index}
              className={`relative rounded-2xl overflow-hidden transition-transform duration-300 hover:scale-105 ${
                plan.popular 
                  ? 'bg-gradient-to-br from-blue-600 to-purple-600 text-white shadow-xl' 
                  : 'bg-white shadow-lg'
              }`}
            >
              {plan.popular && (
                <div className="absolute top-4 right-4 bg-white px-3 py-1 rounded-full text-blue-600 text-sm font-semibold">
                  Most Popular
                </div>
              )}

              <div className="p-8">
                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <div className="flex items-baseline mb-4">
                  <span className="text-2xl">$</span>
                  <span className="text-4xl font-bold">{plan.price}</span>
                </div>
                <p className={`mb-6 ${plan.popular ? 'text-blue-100' : 'text-gray-600'}`}>
                  {plan.description}
                </p>

                {/* Features */}
                <div className="space-y-3 mb-8">
                  {plan.features.map((feature, i) => (
                    <div key={i} className="flex items-center gap-2">
                      <Check className={`w-5 h-5 ${plan.popular ? 'text-blue-200' : 'text-blue-600'}`} />
                      <span className={plan.popular ? 'text-blue-100' : 'text-gray-600'}>
                        {feature}
                      </span>
                    </div>
                  ))}
                </div>

                {/* Add-ons */}
                <div className={`text-sm mb-8 space-y-2 ${plan.popular ? 'text-blue-100' : 'text-gray-500'}`}>
                  <div className="font-semibold">Add-ons available:</div>
                  {plan.addOns.map((addon, i) => (
                    <div key={i}>{addon}</div>
                  ))}
                </div>

                <a
                  href="/services"
                  className={`block text-center py-3 rounded-full font-semibold transition-colors duration-300 ${
                    plan.popular
                      ? 'bg-white text-blue-600 hover:bg-blue-50'
                      : 'bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:from-blue-700 hover:to-purple-700'
                  }`}
                >
                  View Full Details
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className="text-center mt-12">
          <a 
            href="/services" 
            className="inline-flex items-center text-blue-600 font-semibold hover:text-blue-700"
          >
            View Full Pricing Details
            <span className="ml-2">→</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;