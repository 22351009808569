import React, { useState, useEffect } from 'react';
import { Search, Tag, ArrowRight } from 'lucide-react';
import client from '../lib/contentful';

const BlogArchive = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'blogPost',
          order: '-sys.createdAt',
        });
        setPosts(response.items);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Failed to load blog posts');
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // Get unique categories from posts (filter out undefined/null)
  const categories = [
    'all',
    ...new Set(
      posts
        .map(post => post.fields.category)
        .filter(category => typeof category === 'string') // Ensure category is a string
    ),
  ];

  // Filter posts by selected category
  const filteredPosts =
    selectedCategory === 'all'
      ? posts
      : posts.filter(post => post.fields.category === selectedCategory);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-white via-blue-50/30 to-purple-50/30">
        <div className="text-2xl text-blue-600">Loading posts...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-white via-blue-50/30 to-purple-50/30">
        <div className="text-2xl text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-white via-blue-50/30 to-purple-50/30 pt-20">
      {/* Page Header */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">Author Resources & Tips</h1>
          <p className="text-xl text-blue-100 max-w-2xl">
            Insights, guides, and strategies to help authors create compelling websites and grow their audience.
          </p>
        </div>
      </div>

      {/* Search and Filter Section */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-wrap gap-4 items-center justify-between">
          {/* Category Filter */}
          <div className="flex gap-2 flex-wrap">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full transition-all duration-300 ${
                  selectedCategory === category
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-600 hover:bg-blue-50'
                }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Blog Posts Grid */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPosts.map((post) => (
            <article
              key={post.sys.id}
              className="bg-white rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
            >
              <div className="relative h-48">
  {post.fields.featuredImage && (
    <img
      src={`https:${post.fields.featuredImage.fields.file.url}`}
      alt={post.fields.title}
      className="w-full h-full object-cover"
      onError={(e) => {
        console.log('Image failed to load:', e);
        e.target.src = '/api/placeholder/600/400';
      }}
    />
  )}
  <div className="absolute top-4 left-4">
    <span className="px-3 py-1 bg-blue-600 text-white text-sm rounded-full">
      {post.fields.category || 'Uncategorized'}
    </span>
  </div>
</div>

              {/* Content */}
              <div className="p-6">
                <div className="flex justify-between items-center text-sm text-gray-500 mb-4">
                  <span>{new Date(post.fields.date).toLocaleDateString()}</span>
                  <span>{post.fields.readTime || '5 min read'}</span>
                </div>
                <h2 className="text-xl font-bold mb-3 hover:text-blue-600 transition-colors">
                  <a href={`/blog/${post.fields.slug}`}>{post.fields.title}</a>
                </h2>
                <p className="text-gray-600 mb-4 line-clamp-2">
                  {post.fields.excerpt}
                </p>
                <a
                  href={`/blog/${post.fields.slug}`}
                  className="inline-flex items-center text-blue-600 font-semibold hover:text-blue-700 group"
                >
                  Read More
                  <ArrowRight className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                </a>
              </div>
            </article>
          ))}
        </div>

        {/* Empty State */}
        {filteredPosts.length === 0 && (
          <div className="text-center py-12">
            <p className="text-xl text-gray-600">No posts found in this category.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogArchive;