import React from 'react';
import { Link } from 'react-router-dom';

const PageHeader = () => {
  return (
    <header className="fixed top-0 w-full bg-white shadow-md z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src="/assets/images/logo.svg"
            alt="WriteToSite Logo"
            className="h-8 w-auto"
          />
        </Link>

        {/* Navigation */}
        <nav className="flex items-center space-x-6">
          <Link
            to="/"
            className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
          >
            Home
          </Link>
          <Link
            to="/services"
            className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
          >
            Services
          </Link>
          <Link
            to="/contact"
            className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
          >
            Contact
          </Link>
          {/* Get Started Button */}
          <Link
            to="/contact"
            className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-6 py-2 rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300"
          >
            Get Started
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default PageHeader;