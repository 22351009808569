import React from 'react';
import { Calendar, Book, Award, Twitter, Instagram, Youtube } from 'lucide-react';

const AuthorSpotlight = () => {
  const upcomingEvents = [
    {
      date: "Dec 15, 2024",
      title: "Book Signing at Barnes & Noble",
      location: "Seattle, WA"
    },
    {
      date: "Dec 20, 2024",
      title: "Writer's Workshop",
      location: "Virtual Event"
    },
    {
      date: "Jan 5, 2025",
      title: "Literary Festival",
      location: "Portland, OR"
    }
  ];

  const achievements = [
    "New York Times Bestselling Author",
    "#1 Amazon Bestseller in Fantasy",
    "Hugo Award Nominee 2023",
    "Over 1 Million Copies Sold"
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-blue-50 to-purple-50">
      {/* Hero Section */}
      <div className="relative h-[50vh] bg-gradient-to-r from-blue-600 to-purple-600">
        <div className="absolute inset-0 bg-black/30" />
        <div className="container mx-auto px-4 h-full flex items-center relative z-10">
          <div className="text-white max-w-2xl">
            <h1 className="text-5xl font-bold mb-6">Author Spotlight Demo</h1>
            <p className="text-xl opacity-90">
              Showcase your author brand with a professional and engaging web presence. 
              Connect with readers, share your journey, and build your literary career.
            </p>
          </div>
        </div>
      </div>

      {/* Author Profile Section */}
      <div className="container mx-auto px-4 -mt-20">
        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          <div className="flex flex-col md:flex-row gap-8">
            {/* Author Image */}
            <div className="flex-shrink-0">
              <img
                src="/api/placeholder/400/500"
                alt="Author"
                className="w-64 h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
            
            {/* Author Info */}
            <div className="flex-1">
              <div className="flex items-center gap-4 mb-4">
                <h2 className="text-3xl font-bold text-gray-800">Sarah J. Anderson</h2>
                <div className="flex gap-2">
                  <a href="#" className="text-gray-600 hover:text-blue-600 transition-colors">
                    <Twitter size={20} />
                  </a>
                  <a href="#" className="text-gray-600 hover:text-pink-600 transition-colors">
                    <Instagram size={20} />
                  </a>
                  <a href="#" className="text-gray-600 hover:text-red-600 transition-colors">
                    <Youtube size={20} />
                  </a>
                </div>
              </div>
              <p className="text-gray-600 text-lg mb-6 leading-relaxed">
                Award-winning author of fantasy and contemporary fiction. Known for weaving 
                intricate worlds and compelling characters that stay with readers long after 
                the last page. When not writing, Sarah teaches creative writing and hosts 
                workshops for aspiring authors.
              </p>
              <div className="grid md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <h3 className="font-semibold text-gray-800 flex items-center gap-2">
                    <Book className="text-blue-600" size={20} />
                    Published Works
                  </h3>
                  <p className="text-gray-600">12 Novels</p>
                  <p className="text-gray-600">3 Short Story Collections</p>
                </div>
                <div className="space-y-2">
                  <h3 className="font-semibold text-gray-800 flex items-center gap-2">
                    <Award className="text-blue-600" size={20} />
                    Genre Focus
                  </h3>
                  <p className="text-gray-600">Fantasy</p>
                  <p className="text-gray-600">Contemporary Fiction</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Achievements & Events Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {/* Achievements */}
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-2xl font-bold text-gray-800 mb-4 flex items-center gap-2">
              <Award className="text-purple-600" />
              Achievements
            </h3>
            <ul className="space-y-4">
              {achievements.map((achievement, index) => (
                <li 
                  key={index}
                  className="flex items-center gap-3 text-gray-700 bg-gradient-to-r from-purple-50 to-blue-50 p-3 rounded-lg"
                >
                  <span className="h-2 w-2 bg-purple-600 rounded-full"></span>
                  {achievement}
                </li>
              ))}
            </ul>
          </div>

          {/* Upcoming Events */}
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-2xl font-bold text-gray-800 mb-4 flex items-center gap-2">
              <Calendar className="text-blue-600" />
              Upcoming Events
            </h3>
            <div className="space-y-4">
              {upcomingEvents.map((event, index) => (
                <div 
                  key={index}
                  className="flex items-start gap-4 bg-gradient-to-r from-blue-50 to-purple-50 p-4 rounded-lg"
                >
                  <div className="text-center px-3 py-2 bg-white rounded-lg shadow">
                    <div className="text-sm font-semibold text-blue-600">{event.date}</div>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800">{event.title}</h4>
                    <p className="text-gray-600">{event.location}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center pb-20">
          <div className="inline-block animate-bounce-slow mb-6">
            <span className="px-6 py-2 rounded-full bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 font-semibold">
              Start Building Your Author Website Today
            </span>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
            <a
              href="/contact"
              className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium transform hover:scale-105 group"
            >
              Get Started
              <span className="inline-block ml-2 transition-transform group-hover:translate-x-1">→</span>
            </a>
            <a
              href="/pricing"
              className="px-8 py-4 bg-white text-blue-600 rounded-full hover:bg-blue-50 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium border-2 border-blue-600 transform hover:scale-105"
            >
              View Pricing
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorSpotlight;