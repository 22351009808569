import React, { useRef, useState } from "react";
import scoc from "../../src/images/scoc.png";
import chapter1Image from "../../src/images/chapter1.webp";
import book2 from "../../src/images/book2.png"
import book3 from "../../src/images/book3.png"
import book4 from "../../src/images/book4.png"
import book5 from "../../src/images/book5.jpg"
import book6 from "../../src/images/book6.png"
import book7 from "../../src/images/book7.png"
import book8 from "../../src/images/book8.jpg"

const DemoPreview = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const sections = {
    intro: useRef(null),
    chapter1: useRef(null),
    chapter2: useRef(null),
    audio: useRef(null),
  };

  // Sample book series data with imported image
  const bookSeries = [
    {
      id: 1,
      title: "The Sinister Coven of Cruor",
      cover: scoc,
      description: "Begin the epic journey with the first installment of the series. Follow our hero as they discover their true destiny.",
      purchaseLink: "#"
    },
    {
      id: 2,
      title: "The Children of the Night",
      cover: book2,
      description: "The adventure continues as dark forces gather and alliances are tested in this thrilling sequel.",
      purchaseLink: "#"
    },
    {
      id: 3,
      title: "Awakening the Grey",
      cover: book3,
      description: "Secrets are revealed and loyalties are questioned in this pivotal third installment.",
      purchaseLink: "#"
    },
    {
      id: 4,
      title: "The Saturnian Talisman",
      cover: book4,
      description: "Everything comes to a head in this explosive fourth book as our heroes face their greatest challenge yet.",
      purchaseLink: "#"
    },
    {
      id: 5,
      title: "Into the Stygia",
      cover: book5,
      description: "The stunning conclusion to the epic saga brings new hope and unexpected twists.",
      purchaseLink: "#"
    },
    {
      id: 6,
      title: "LWC Origins: The Elder Witches",
      cover: book6,
      description: "The stunning conclusion to the epic saga brings new hope and unexpected twists.",
      purchaseLink: "#"
    },
    {
      id: 7,
      title: "LWC Origins: The Nocturnal Baron",
      cover: book7,
      description: "The stunning conclusion to the epic saga brings new hope and unexpected twists.",
      purchaseLink: "#"
    },
    {
      id: 8,
      title: "LWC Origins: The Pharaoh's Pact",
      cover: book8,
      description: "The stunning conclusion to the epic saga brings new hope and unexpected twists.",
      purchaseLink: "#"
    }
  ];

  const scrollToSection = (section) => {
    sections[section].current.scrollIntoView({ behavior: "smooth" });
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const openBookOverlay = (book) => {
    setSelectedBook(book);
  };

  const closeBookOverlay = () => {
    setSelectedBook(null);
  };

  return (
    <section className="py-20 px-4 bg-gradient-to-br from-white via-blue-50 to-purple-50 relative">
      <div className="container mx-auto">
        {/* Heading */}
        <div className="mb-12 text-center">
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
            HTML Book Preview Demo
          </h1>
          <p className="text-xl text-gray-600 mt-4">
            Give your readers an immersive experience. Share preview chapters
            or grant exclusive access to the entire book for members. Add audio
            files for an interactive "read-along" experience or let readers
            enjoy the audio version directly.
          </p>
        </div>

        {/* Main Content */}
        <div className="grid lg:grid-cols-3 gap-8">
          {/* Left - Table of Contents */}
          <div className="rounded-2xl p-6 shadow-lg lg:sticky top-20 lg:h-[80vh] bg-gradient-to-br from-purple-500 via-purple-600 to-purple-700 text-white">
            <h2 className="text-xl font-bold mb-4">Table of Contents</h2>
            <ul className="space-y-4">
              <li>
                <button
                  onClick={() => scrollToSection("intro")}
                  className="hover:text-blue-200 transition-colors"
                >
                  Introduction
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("chapter1")}
                  className="hover:text-blue-200 transition-colors"
                >
                  Chapter 1: The Beginning
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("chapter2")}
                  className="hover:text-blue-200 transition-colors"
                >
                  Chapter 2: The Conflict
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("audio")}
                  className="hover:text-blue-200 transition-colors"
                >
                  Audio Version
                </button>
              </li>
            </ul>
            {/* Button to Trigger Popup */}
            <div className="mt-6">
              <button
                onClick={togglePopup}
                className="w-full px-4 py-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg"
              >
                See Book Popup Preview
              </button>
            </div>
          </div>

          {/* Right - Preview Content */}
          <div className="bg-white rounded-2xl p-6 shadow-lg overflow-y-auto lg:h-[80vh] lg:col-span-2">
            {/* Introduction */}
            <div ref={sections.intro} className="space-y-4 mb-8">
              <h2 className="text-2xl font-bold text-gray-800">Introduction</h2>
              <p className="text-gray-600 leading-relaxed">
                Welcome to the HTML Book Preview Demo! Here, you can see how
                beautifully formatted book previews can engage your readers.
                Whether it's a gripping prologue or an enticing first chapter,
                HTML previews offer a web-friendly way to showcase your work.
              </p>
            </div>

            {/* Chapter 1 */}
            <div ref={sections.chapter1} className="space-y-4 mb-8">
              <h2 className="text-2xl font-bold text-gray-800">
                Chapter 1: The Beginning
              </h2>
              <p className="text-gray-600 leading-relaxed">
              Nestled in a quiet clearing, surrounded by the ancient and towering trees of 
the mountain forest, stood a small cabin. Its aged wooden exterior blended 
seamlessly with the natural surroundings, as if nature itself had constructed the 
dwelling. A birdsong filled the air, harmonizing with the gentle rustling of leaves 
in the breeze, creating a symphony of tranquility. The seclusion of this haven 
made it the ideal sanctuary for the Leclair sisters to practice their magical arts 
without fear of discovery or interruption. Get the book to continue reading...
              </p>
              <img
  src={chapter1Image}
  alt="Chapter 1 illustration"
  className="rounded-xl shadow-md w-full max-w-4xl mx-auto"  // Added some classes for better sizing
/>
            </div>

            {/* Chapter 2 */}
            <div ref={sections.chapter2} className="space-y-4 mb-8">
              <h2 className="text-2xl font-bold text-gray-800">
                Chapter 2: The Conflict
              </h2>
              <p className="text-gray-600 leading-relaxed">
              The secluded mountain cabin was nestled in the heart of the woods, surrounded by towering trees and a thick blanket of fog. It was here that 
Cassandra Leclair stood, her long dark hair cascading down her back as she raised 
her hands to the sky. Her piercing eyes focused on the swirling energy before her, 
a manifestation of her raw magical power.
              </p>
              <p className="text-gray-600 leading-relaxed">
              "Remember, Cassandra," Selene cautioned, her wavy chestnut hair framing her 
              gentle face. "Control is key." 

              Cassandra nodded, exhaling deeply as she guided the energy into the form of 
a roaring lion. She could feel its strength, its fierce determination - a reflection 
of her own ambition. The lion paced the wooden Foor, leaving behind glowing 
embers in its wake. Get the book to continue reading...
              </p>
            </div>

            {/* Audio Section */}
            <div ref={sections.audio} className="space-y-4 mb-8">
              <h2 className="text-2xl font-bold text-gray-800">Audio Version</h2>
              <p className="text-gray-600 leading-relaxed">
                Prefer to listen? Enjoy the first chapter of the audio version
                below.
              </p>
              <audio
                controls
                className="w-full rounded-lg shadow-md"
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "12px",
                }}
              >
                <source
                  src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                  type="audio/mpeg"
                />
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
        </div>
      </div>

      {/* Book Series Showcase Section */}
      <div className="container mx-auto mt-20">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 mb-6">
            Showcase Your Book Series
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Create stunning book series displays that captivate your readers. Each book cover floats elegantly, inviting interaction, while smooth animations and beautiful overlays provide detailed information about each installment.
          </p>
        </div>

        {/* Floating Book Series Display */}
        <div className="flex flex-wrap justify-center gap-8">
          {bookSeries.map((book) => (
            <div
              key={book.id}
              className="relative group cursor-pointer"
              onClick={() => openBookOverlay(book)}
            >
              <div className="relative animate-book-float transition-transform">
                <div className="absolute inset-0 bg-black opacity-20 rounded-lg transform translate-y-4 blur-lg"></div>
                <img
                  src={book.cover}
                  alt={book.title}
                  className="relative w-48 h-72 rounded-lg shadow-xl transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-2xl"
                />
                {/* Hover Sparkle Effect */}
                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity">
                  {[...Array(6)].map((_, i) => (
                    <div
                      key={i}
                      className="absolute animate-sparkle"
                      style={{
                        top: `${Math.random() * 100}%`,
                        left: `${Math.random() * 100}%`,
                        width: '8px',
                        height: '8px',
                        background: 'white',
                        borderRadius: '50%',
                        animationDelay: `${Math.random() * 2}s`,
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Book Overlay */}
      {selectedBook && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
          onClick={closeBookOverlay}
        >
          <div
            className="bg-white rounded-2xl shadow-2xl p-8 max-w-2xl w-full transform transition-all"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeBookOverlay}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
            >
              ✕
            </button>
            <div className="flex flex-col md:flex-row gap-8">
              <div className="flex-shrink-0">
                <img
                  src={selectedBook.cover}
                  alt={selectedBook.title}
                  className="w-64 h-96 rounded-lg shadow-xl"
                />
              </div>
              <div className="flex-1">
                <h3 className="text-3xl font-bold text-gray-800 mb-4">
                  {selectedBook.title}
                </h3>
                <p className="text-gray-600 text-lg mb-8 leading-relaxed">
                  {selectedBook.description}
                </p>
                <a
                  href={selectedBook.purchaseLink}
                  className="inline-block px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium transform hover:scale-105"
                >
                  Purchase Now
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Original Preview Popup */}
      {isPopupOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center"
          onClick={togglePopup}
        >
          <div
            className="relative bg-white rounded-2xl shadow-xl p-6 w-11/12 max-w-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={togglePopup}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <div className="text-center">
              <img
                src={scoc}
                alt="Book Cover"
                className="w-48 h-64 mx-auto rounded-lg shadow-md mb-4"
              />
              <h3 className="text-2xl font-bold text-gray-800 mb-2">
                The Amazing Book Title
              </h3>
              <p className="text-gray-600 text-sm mb-6">
                This is a summary of the book. It highlights key aspects of the
                story and gives readers an exciting reason to buy.
              </p>
              <a
                href="#"
                className="px-6 py-3 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-full hover:from-purple-700 hover:to-blue-700 transition-all duration-300 shadow-md hover:shadow-lg text-lg font-medium"
              >
                Buy Now
              </a>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default DemoPreview;