import React from 'react';
import { BookOpen, Search, Palette, Link, ArrowRight, Laptop } from 'lucide-react';

const BookPreviewSection = () => {
  const features = [
    {
      icon: <Palette className="w-6 h-6" />,
      title: "Custom Styling",
      description: "HTML preview pages match your website's design for a seamless, professional look",
    },
    {
      icon: <Search className="w-6 h-6" />,
      title: "SEO Optimized",
      description: "Previews are crafted to help your book appear in search results",
    },
    {
      icon: <Link className="w-6 h-6" />,
      title: "Interactive Experience",
      description: "Include clickable links to purchase the book directly from the preview page",
    },
  ];

  return (
    <section className="py-20 px-4 bg-gradient-to-br from-white via-blue-50 to-purple-50 relative overflow-hidden">
      {/* Decorative Background */}
      <div className="absolute inset-0 opacity-5">
        <div className="absolute transform rotate-12 -right-40 -top-40">
          <BookOpen size={400} />
        </div>
      </div>

      <div className="container mx-auto relative z-10">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Left Content */}
          <div className="space-y-8">
            <div className="space-y-4">
              <span className="px-4 py-2 rounded-full bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600 font-semibold inline-block">
                HTML Book Preview
              </span>
              <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
                Start Reading Before You Buy
              </h2>
              <p className="text-xl text-gray-600">
                Give your readers a sneak peek! Share the first few chapters of your book in an engaging, web-friendly format.
              </p>
            </div>

            <div className="bg-white rounded-2xl p-6 shadow-lg">
              <h3 className="text-xl font-bold text-gray-800 mb-4">Why It Matters</h3>
              <p className="text-gray-600">
                Readers love to try before they buy. A beautifully formatted HTML preview gives them a taste of your story and builds excitement for the full book.
              </p>
            </div>

            <div className="grid grid-cols-1 gap-6">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="flex items-start space-x-4 bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
                >
                  <div className="p-3 rounded-lg bg-gradient-to-r from-blue-600/10 to-purple-600/10 text-blue-600">
                    {feature.icon}
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-800 mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Content - Preview Mockup */}
          <div className="relative">
            <div className="relative rounded-2xl overflow-hidden shadow-2xl">
              <div className="aspect-w-9 aspect-h-16 bg-white rounded-2xl p-8">
                {/* Preview Mockup Content */}
                <div className="space-y-6">
                  <div className="flex justify-between items-center border-b pb-4">
                    <h4 className="text-2xl font-bold text-gray-800">Chapter 1: The Beginning</h4>
                    <Laptop className="text-blue-600" size={24} />
                  </div>
                  <div className="space-y-4 text-justify">
                    <p className="text-gray-600 leading-relaxed">
                      The sun dipped below the horizon, painting the sky in hues of amber and crimson. Sarah tightened her scarf against the cool breeze, her
                      heart racing with anticipation. In the distance, the faint hum of the city beckoned her forward, a reminder that tonight, everything
                      could change.
                    </p>
                    <p className="text-gray-600 leading-relaxed">
                      She clutched the weathered journal in her hands, its pages filled with secrets of a life she barely remembered. Each step felt heavier,
                      her resolve tested with every passing moment. But she had to know the truth—a truth buried beneath layers of time and regret.
                    </p>
                    <p className="text-gray-600 leading-relaxed">
                      As she approached the old bridge, a shadow emerged from the fog. "You came," the figure said, voice trembling with equal parts relief and
                      fear. And just like that, Sarah knew: this was only the beginning.
                    </p>
                    <div className="h-32 bg-gradient-to-b from-transparent to-white absolute bottom-0 left-0 right-0" />
                  </div>
                </div>
              </div>
            </div>

            {/* How It Works */}
            <div className="mt-8 bg-white rounded-xl p-6 shadow-lg">
              <h3 className="text-xl font-bold text-gray-800 mb-4">How It Works</h3>
              <p className="text-gray-600">
                We'll take the first few chapters of your book, format them for the web, and integrate them into your site. Whether it's a gripping prologue
                or the first 10 pages, you choose what to showcase.
              </p>
            </div>
          </div>
        </div>

        {/* CTA Buttons */}
        <div className="flex flex-col sm:flex-row gap-4 justify-center mt-16">
          <a
            href="/demo-preview"
            className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium flex items-center justify-center group"
          >
            Explore a Demo Preview
            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" size={20} />
          </a>
          <a
            href="/contact"
            className="px-8 py-4 border-2 border-blue-600 text-blue-600 rounded-full hover:bg-blue-50 transition-all duration-300 shadow-lg hover:shadow-xl text-lg font-medium flex items-center justify-center"
          >
            Get Started Today
          </a>
        </div>
      </div>
    </section>
  );
};

export default BookPreviewSection;
