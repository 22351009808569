import React, { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import client from '../lib/contentful';

const TermsOfUse = () => {
  const [terms, setTerms] = useState(null);
  const [loading, setLoading] = useState(true);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-4 text-gray-600">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-3xl font-bold mb-4 text-gray-900">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="text-2xl font-bold mb-3 text-gray-900">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="text-xl font-bold mb-2 text-gray-900">{children}</h3>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc pl-6 mb-4 text-gray-600">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="list-decimal pl-6 mb-4 text-gray-600">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="mb-2">{children}</li>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a 
          href={node.data.uri}
          className="text-blue-600 hover:text-blue-800 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote className="border-l-4 border-blue-600 pl-4 my-4 italic text-gray-600">
          {children}
        </blockquote>
      ),
      [BLOCKS.HR]: () => (
        <hr className="my-8 border-gray-200" />
      ),
      [BLOCKS.TABLE]: (node, children) => (
        <div className="overflow-x-auto my-8">
          <table className="min-w-full border-collapse border border-gray-200">
            {children}
          </table>
        </div>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => (
        <tr className="border-b border-gray-200">{children}</tr>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => (
        <td className="px-4 py-2 text-gray-600">{children}</td>
      ),
    }
  };

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'termsOfUse',
          limit: 1,
        });
        console.log('Terms Response:', response); // Debug log
        if (response.items.length > 0) {
          setTerms(response.items[0]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching terms of use:', error);
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen pt-24 flex items-center justify-center">
        <div className="text-2xl text-blue-600">Loading...</div>
      </div>
    );
  }

  if (!terms) {
    return (
      <div className="min-h-screen pt-24 flex items-center justify-center">
        <div className="text-2xl text-red-600">Content not found</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-24 bg-gradient-to-b from-white via-blue-50/30 to-purple-50/30">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-lg p-8">
          <h1 className="text-4xl font-bold mb-6 text-gray-900">
            {terms.fields.title}
          </h1>
          
          <div className="text-sm text-gray-500 mb-8">
            Last Updated: {new Date(terms.fields.lastUpdated).toLocaleDateString()}
          </div>

          <div className="prose prose-lg max-w-none">
            {terms.fields.content && documentToReactComponents(terms.fields.content, options)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;