import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import client from '../lib/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const BlogPostTemplate = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  // Rich text rendering options
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-4">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-3xl font-bold mb-4">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="text-2xl font-bold mb-3">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="text-xl font-bold mb-2">{children}</h3>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc ml-6 mb-4">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="list-decimal ml-6 mb-4">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="mb-2">{children}</li>
      ),
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      console.log('Current slug:', slug);
      try {
        const response = await client.getEntries({
          content_type: 'blogPost',
          'fields.slug': slug
        });

        console.log('Contentful response:', response);

        if (response.items.length > 0) {
          setPost(response.items[0]);
          console.log('Post content:', response.items[0]);
        } else {
          setError('Post not found');
        }
      } catch (error) {
        console.error('Error details:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="min-h-screen pt-24 flex items-center justify-center">
        <div className="text-2xl text-blue-600">Loading post...</div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen pt-24 flex flex-col items-center justify-center">
        <div className="text-2xl text-red-600 mb-4">
          {error || 'Post not found'}
        </div>
        <a 
          href="/blog"
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Return to blog
        </a>
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-24 pb-8 px-4 bg-gradient-to-b from-white via-blue-50/30 to-purple-50/30">
      <div className="container mx-auto max-w-4xl">
        {/* Back Button */}
        <a 
          href="/blog" 
          className="inline-flex items-center text-blue-600 hover:text-blue-700 font-medium group mb-8"
        >
          <ArrowLeft className="w-4 h-4 mr-2 transform group-hover:-translate-x-1 transition-transform" />
          Back to Blog
        </a>

        {/* Article Content */}
        <article className="bg-white rounded-2xl shadow-xl p-8">
          <div className="mb-4">
            <span className="px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium">
              {post.fields.category || 'Uncategorized'}
            </span>
          </div>

          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            {post.fields.title}
          </h1>

          {post.fields.date && (
            <div className="text-gray-600 mb-6">
              {new Date(post.fields.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </div>
          )}

          <div className="prose prose-lg max-w-none">
            {documentToReactComponents(post.fields.content, options)}
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPostTemplate;